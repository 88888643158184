.modules-access-page {
  padding: 20px;
  border-radius: 8px;
}

.module-groups {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}

.module-group h3 {
  color: #706F6F;
  margin-bottom: 16px;
}

.module-item {
  margin-bottom: 10px;
}

.module-item .MuiFormControlLabel-root {
  margin-bottom: 10px; 
  display: block;
  color: #706F6F;
}

.Button {
  background-color: #00CFCA;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Button:hover {
  background-color: #029d9b; 
}

.Checkbox {
  accent-color: #15afad;
}
