.custom-checkbox {
  display: grid;
  background: white;
  width: fit-content;
  grid-template-areas: "custom_checkbox_control";
  margin-right: 10px;

  input {
    opacity: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

    .custom_checkbox_control {
        border: 1px solid #D0D5DC;
        display: inline-grid;
        align-content: center;
        cursor: pointer;

    i {
      background-color: white;
      visibility: hidden;
    }

    &.indeterminate {
      i {
        background-color: white;
        color: #31D72F;
        visibility: visible;
      }
    }
  }

  .input-checkbox:checked + .custom_checkbox_control i {
    background-color: #31D72F;
    visibility: visible;
    color: white;
    font-weight: bold;
  }
}
