.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    z-index: 700;
    outline: 0;
    border-radius: 4px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.right {
    float: right;
}

.w120 {
    width: auto;
    min-width: 120px;
}

.w210 {
    width: 210px;
}

.w237 {
    width: 237px;
}

.w494 {
    width: 494px;
}

.mg-r-16 {
    margin-right: 16px;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 500;
}

.styleModal {
    z-index: 100;
    background: white;
    position: relative;
    margin: auto;
    border-radius: 8px;
}

label {
    display: grid;
    input {
        top: 604px;
        left: 490px;
        height: 34px;
        width: 100%;
        border: none;
        background: #F5F6FA 0% 0% no-repeat padding-box;
        border-radius: 3px;
        opacity: 1;
        text-align: left;
        font: normal normal 300 16px Myriad Pro;
        letter-spacing: 0px;
        color: #706F6F;
    }

    .custom-checkbox {
        padding-right: 29px
    }
}



.headerModal {
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
}

.headerText {
    text-align: left;
    font: normal normal 300 22px/30px Myriad Pro;
    letter-spacing: 0px;
    color: #706F6F;
}

.contentModal {
    font-size: 0.8rem;
    border: none;
    border-radius: 3px;
    margin-left: 0.5rem;
    background: none;
    :hover {
      cursor: pointer;
    }
}

.closeButton {
    padding: 10px;
    max-height: 30rem;
    overflow-x: hidden;
    overflow-y: auto;
}