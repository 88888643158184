.header {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: "Myriad Pro";

  .title {
      text-align: left;
      font-size: 40px;
      font-weight: 500;
      color: #565b5c;
  }

  .account-name {
    text-align: left;
    font-size: 30px;
    font-weight: 400;
    color: #565b5c;
  }

  .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
  }

  .tabs {
      .MuiTab-root {
          text-align: left;
          font-size: 20px;
          color: #352D2B;
          text-transform: none;
      }
      .MuiTab-textColorInherit.Mui-selected {
          color: #18D0CC;
          font-weight: bold;
      }
      .MuiTabs-indicator {
          background-color: #18D0CC;
      }
  }

  @keyframes loading {
      from { width: 0; }
      to { width: 100%; }
  }

  .progress-bar {
      background-color: #18D0CC;
      height: 5px;
      animation: loading 2s linear forwards;
  }
}
