.uploadButton {
    top: 446px;
    left: 652px;
    width: 100px;
    height: 34px;
    background: #18D0CC 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
}

#uploadInput {
    display: none;
}