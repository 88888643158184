.clients-list {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #131842;
}

.total-clients {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: #7f8c8d;
}

.table-container {
    overflow-x: auto;
}

.clients-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.clients-table th,
.clients-table td {
    padding: 12px 15px;
}

.clients-table th:nth-child(1),
.clients-table td:nth-child(1) {
    width: 25%;
}

.clients-table th:nth-child(2),
.clients-table td:nth-child(2) {
    width: 45%;
}

.clients-table th:nth-child(3),
.clients-table td:nth-child(3),
.clients-table th:nth-child(4),
.clients-table td:nth-child(4),
.clients-table th:nth-child(5),
.clients-table td:nth-child(5) {
    width: 15%;
}

.clients-table th {
    background-color: #06b4af;
    color: #ecf0f1;
    font-weight: bold;
    text-transform: uppercase;
}

.clients-table tbody tr {
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s ease-in-out;
}

.clients-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.clients-table tbody tr:hover {
    background-color: #f1f1f1;
}

.clients-list .clients-table th.align-left,
.clients-list .clients-table td.align-left {
    text-align: left !important;
}

.clients-list .clients-table th.align-right,
.clients-list .clients-table td.align-right {
    text-align: right !important;
    width: 100px;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.rows-per-page {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.rows-per-page label {
    margin-right: 5px;
    font-weight: bold;
}

.rows-per-page select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.page-controls {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.page-controls button {
    padding: 8px 12px;
    margin: 0 5px;
    background-color: #00bfb9;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-controls button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
}

.page-controls button:hover:not(:disabled) {
    background-color: #008b87;
}

.page-controls span {
    margin: 0 10px;
    font-weight: bold;
}

.export-button {
    display: inline-block;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #00bfb9;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.export-button:hover {
    background-color: #008b87;
}