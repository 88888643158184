.releases-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.releases-table th,
.releases-table td {
    padding: 12px 15px;
}

.releases-table th:nth-child(1),
.releases-table td:nth-child(1) {
    width: 10%;
}

.releases-table th:nth-child(2),
.releases-table td:nth-child(2) {
    width: 75%;
}

.releases-table th:nth-child(3),
.releases-table td:nth-child(3),
.releases-table th:nth-child(4),
.releases-table td:nth-child(4),
.releases-table th:nth-child(5),
.releases-table td:nth-child(5) {
    width: 5%;
}

.releases-table th {
    background-color: #06b4af;
    color: #ecf0f1;
    font-weight: bold;
    text-transform: uppercase;
}

.checkboxCell {
    width: 24px;
}

.tableCell {
    opacity: 1;
    padding: 6px 24px 6px 16px;
    text-align: left;
    font: normal normal normal 16px/22px Myriad Pro;
    letter-spacing: 0px;
    width: 18%
}