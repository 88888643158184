$fonts-root: '/fonts/';

@font-face {
  font-family: 'Myriad Pro';
  src: url('/assets#{$fonts-root}MyriadPro-Light.eot');
  src: url('/assets#{$fonts-root}MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
      url('/assets#{$fonts-root}MyriadPro-Light.woff2') format('woff2'),
      url('/assets#{$fonts-root}MyriadPro-Light.woff') format('woff'),
      url('/assets#{$fonts-root}MyriadPro-Light.ttf') format('truetype'),
      url('/assets#{$fonts-root}MyriadPro-Light.svg#MyriadPro-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: url('/assets#{$fonts-root}MyriadPro-Regular2.eot');
  src: url('/assets#{$fonts-root}MyriadPro-Regular2.eot?#iefix') format('embedded-opentype'),
      url('/assets#{$fonts-root}MyriadPro-Regular2.woff2') format('woff2'),
      url('/assets#{$fonts-root}MyriadPro-Regular2.woff') format('woff'),
      url('/assets#{$fonts-root}MyriadPro-Regular2.ttf') format('truetype'),
      url('/assets#{$fonts-root}MyriadPro-Regular2.svg#MyriadPro-Regular2') format('svg');
  font-weight: 500;
  font-style: normal;
}

