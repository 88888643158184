.background-admin{
    width: 100%;
    position: absolute;
}

.frame {
    background-color: white;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    margin: 68px 140px 68px 140px;
    padding-bottom: 36px;
    min-width: fit-content;
}

.MuiFormLabel-root.Mui-focused {
    color: #18D0CC !important;
}
.MuiInput-underline::after {
border-bottom: 2px solid #18D0CC !important;
}
.MuiButton-textPrimary {
    color: #18D0CC !important;
}