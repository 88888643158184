.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 24px;
    color: #333;
    position: relative;
  }
  
  .loader::before {
    content: "";
    width: 40px;
    height: 40px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #00CFCA;
    border-radius: 50%;
    position: absolute;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  