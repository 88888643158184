.dropdownMenu{
    max-width: 270px;
    position: absolute;
    border: 1px solid #c1c1c1;
    border-radius: 2px;
    margin-top: 5px;
    background: white;
    .dropdownBtn {
        width: 100%;
        border: none;
        background: #FFFFFF;
        text-align: left;
        font: normal normal 300 16px/22px Myriad Pro;
        letter-spacing: 0px;
        color: #706F6F;
        opacity: 1;
    }

    .dropdownBtn:hover{
        color: white;
        background: #00CFCA;
    }
}

.buttonAction {
    background: #D0D5DD 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    text-align: left;
    font: normal normal 300 16px/22px Myriad Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    border: none;
    height: 34px;
    width: 100%;
    padding-left: 15px;
    line-height: inherit;
}

button i.material-icons{
    pointer-events: none;
    float: right;
}