.tag {
    top: 373px;
    left: 290px;
    width: 125px;
    height: 33px;
    background: #18D0CC 0% 0% no-repeat padding-box;
    border-radius: 16px;

    .text{
        text-align: left;
        font: normal normal normal 16px/22px Myriad Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }
}

.icon {
    border-radius: 50%;
    border: 2px solid #FFF;
    margin-left: 2px;
    height:70%;
}