ul {
    display: flex;
    border-bottom: 1px solid #e9e9e9;
    margin-left: 36px;
    margin-right: 36px;
    padding-inline-start: 0;
}

li {
    list-style-type: none;
    button {
        border: none;
        background: none;
        text-align: left;
        font: normal normal normal 16px Myriad Pro;
        letter-spacing: 0px;
        color: #352D2B;
        opacity: 1;
        padding-right: 15px;
        padding-left: 15px;

        &.selected {
            border-bottom: 1px solid #18D0CC;
            color: #18D0CC;
        }
    }
}