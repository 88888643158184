.country-list {
    width: 100%;
  
    .form-control {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
  
      .select-label {
        margin-bottom: 10px;
      }
  
      .select {
        width: 100%;
        position: relative;

        .menu-item {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          width: 100%;
          padding: 10px;
          border-bottom: 1px solid #f0f0f0;
          &:hover {
            background-color: #f4f4f4;
          }
  
          .text {
            flex-grow: 1;
          }
  
          .icon-button {
            margin-left: 10px;
          }
        }
      }
    }
  
    .button-container {
      display: flex;
      justify-content: flex-end;
  
      .add-button {
        padding: 8px 16px;
        font-size: 0.875rem;
        text-transform: uppercase;
      }
    }
  }
  .menu-paper {
    max-height: 300px;
    overflow-y: auto;
  }

.MuiList-root {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    .MuiMenuItem-root {
      width: 100%;
      border-bottom: 1px solid #f0f0f0; 
    }
  }
  
  .MuiMenu-list {
    padding: 0;
  }
