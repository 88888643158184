* {
  margin: 0;
} 

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.customButton {
  width: 100px;
  height: 34px;
  text-align: center;
  font: normal normal 300 16px/22px Myriad Pro;
  border-radius: 3px;

  &.valid-btn {
    background: #18D0CC 0% 0% no-repeat padding-box;
    border: 1px solid #00CFCA;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    &:disabled{
      background: #7f8c8d;
      border: 1px solid #7f8c8d;
      &:hover{
        background: #7f8c8d;
        border: 1px solid #7f8c8d;
      }
    }
    &:hover{
      background: #15afad 0% 0% no-repeat padding-box;
      border: 1px solid #15afad;
  }  
  }

  &.cancel-btn {
    letter-spacing: 0px;
    border: 1px solid #00CFCA;
    color: #00CFCA;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
  }

  &.red-btn {
    background: #FF0624 0% 0% no-repeat padding-box;
    border: 1px solid #FF0624;
    opacity: 1;
    color: #FFFFFF;
  }

  &.disabled {
    background: #D0D5DD 0% 0% no-repeat padding-box;
    border: 1px solid #D0D5DD;
    opacity: 1;
    color: #FFFFFF;
  }

  &.margin5 {
    margin: 5px;
  }
}

select {
  word-wrap: normal;
  border: 1px solid #C1C1C1;
  border-radius: 3px;
  opacity: 1;
  width: 290px;
  height: 34px;
  font: normal normal 300 16px/22px Myriad Pro;
  letter-spacing: 0px;
  text-indent: 10px;
  color: #706F6F;
}

.root{
  height: inherit;
}
