input, label {
    text-align: left;
}

.inputs {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 5px;
    font: normal normal 300 16px/22px Myriad Pro;
    color: #706F6F;
    margin: 10px;
}

.subtitle {
    h2{
        text-align: left;
        font-size: 20px;
        font-family: "Myriad Pro";
        font-weight: 400;
        letter-spacing: 0px;
        color: #AEAFB1;
        opacity: 1;
    
    }
}
.oneRow {
    display: flex;
    padding-top: 40px;
}

.padlr20 {
    padding-left: 20px;
    padding-right: 20px;
}

.inputs {
    display: flex;
    align-items: flex-end;
}

.left {
    width: 30%
}