.login-background {
    background-color: white;
    width: 450px;
    height: 500px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
}

.login-label {
    padding-left: 55px;
}

.login-icon {
    position: relative;
    padding-top: 10px;
    padding-bottom: 35px;
    img {
        display: block;
        margin: auto;
        margin-top: 3vh;
        width: 44px;
        height: 40px;
    }
}

.login-title {
    margin-top: 2vh;
    margin-bottom: 50px;
    h1 {
        text-align: center;
        font-size: 24px;
        font-family: "Myriad Pro";
        letter-spacing: 0px;
        color: #706F6F;
        opacity: 1;
    }
}

.login-input {
    h2 {
        text-align: left;
        font-size: 18px;
        font-family: "Myriad Pro";
        font-weight: 600;
        letter-spacing: 0px;
        color: #706F6F;
        opacity: 1;
        margin-bottom: 9px;
    } 
    input {
        width: 360px;
        height: 34px;
        background: #F5F6FA 0% 0% no-repeat padding-box;
        border-radius: 3px;
        border: none;
        opacity: 0.8;
        outline: none;
    }
    
    .error {
        border: 1px solid red;
    }

    input::placeholder {
        text-align: left;
        font: normal normal 300 15px/22px Myriad Pro;
        letter-spacing: 0px;
        color: #706F6F;
        opacity: 0.5;
    }

    .msg-error {
        padding-top: 4px;
        padding-left: 55px;
        text-align: left;
        font: normal normal 300 11px/15px Myriad Pro;
        letter-spacing: 0px;
        color: #FF0624;
        opacity: 1;
    }
}

.link {
    width: auto;
    text-align: left;
    font: normal normal 300 12px/21px Myriad Pro;
    letter-spacing: 0px;
    color: #706F6F;
    opacity: 1;
    padding-top: 16px;
    padding-left: 53px;
    a {
        color: #706F6F;
    }
    a:hover {
        color: #706F6F;
    }
}

.checkbox {
    padding-top: 41px;
    padding-left: 45px;
    text-align: left;
    input[type="checkbox"] {
        width: 24px;
        height: 24px;
        border: 1px solid #D0D5DC;
        opacity: 1;
    }
    label {
        position: relative;
        bottom: 7px;
        padding-left: 9px;
        text-align: left;
        font: normal normal 300 14px/21px Myriad Pro;
        letter-spacing: 0px;
        color: #706F6F;
        opacity: 1;
    }
}

.btn-valid {
    width: 120px;
    height: 34px;
    background: #D0D5DD 0% 0% no-repeat padding-box;
    border-radius: 3px;
    border: none;
    opacity: 1;
    color: white;
    position:absolute;
    right: 40px;
    bottom: 61px;
    align-self: center;
    &:hover{
        background: #707070 0% 0% no-repeat padding-box;
    }  
}

.loginButton {
    position: absolute;
    right: 0;
    bottom: 61px;
}

.center {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

.mg-right40 {
    margin-right: 40px;
}

.footer {
    text-align: left;
    font: normal normal 300 12px/16px Myriad Pro;
    letter-spacing: 0px;
    color: #706F6F;
    opacity: 1;
    position:absolute;
    right: 40px;
    bottom: 10px;
}

.hide {
    display: none;
}