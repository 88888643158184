.client-options-page {
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.client-options-page h2 {
    color: #333; 
    margin-bottom: 16px;
}

.client-options-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.client-options-group > div {
    flex-basis: calc(50% - 10px);
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.FormControlLabel {
    margin-bottom: 10px;
    display: block;
    color: #333;
}

.Button {
    background-color: #00CFCA;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Button:hover {
    background-color: #029d9b;
}

.Checkbox {
    accent-color: #15afad;
}
.client-options-page .template-list {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
    background-color: #f0f0f0; 
}

.client-options-page .template-item {
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.client-options-page .add-template-button {
    margin-top: 10px;
    background-color: #4CAF50; 
    color: white;
}

.client-options-page .template-item .answer-input {
    width: 100%; 
    margin-top: 10px;
}