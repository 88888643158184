@import "../../theme/sass/colorScheme/evermaps";
@import "../../theme/sass/_myriadpro.scss";

$startcolor: #A6EEEC;

.background-home {
    background: linear-gradient(45deg, $startcolor, $turquoise);
    height: 100vh;
}
.logo {
    right:20px;
    bottom: 20px ;
    align-self: center;
    width: inherit;
}
.centered {
    height: 100vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
  }

.Login {
    height: 100vh; /* Magic here */
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-right {
    float: left;
    position: absolute;
}

.w10pc {
    width: 10%;
}